@use "./color";

// Make links clear where they may not be seen
a.lit {
  text-decoration: underline;
}

// Aspect ratio
// https://css-tricks.com/aspect-ratio-boxes/
[style*="--aspect-ratio"] {
  position: relative;
  &::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  > img {
    height: auto;
  }
}

.fromSystem {
  font-style: italic;
  .lit {
    font-style: initial;
  }
}

.bgIsDark {
  color: color.$text-inverted;
}

.bgIsDark:hover {
  color: color.$link-hover-text-inverted;
}

.bgIsLight {
  color: color.$text;
}

.bgIsLight:hover {
  color: color.$link-hover-text;
}

.awvc .no-awvc.no-awvc.no-awvc.no-awvc {
  display: none !important;
}

.native .base-v5 .no-lwvc.no-lwvc.no-lwvc.no-lwvc {
  display: none !important;
}
.lwvc-only {
  display: none;
}
.native .base-v5 .lwvc-only {
  display: unset;
}

[data-headlessui-state] {
  flex-shrink: 0;
}
