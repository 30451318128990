.container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &.fullPage {
    padding-top: 33vh;
    text-align: center;
    width: var(--full-column-width);
  }

  .title {
    color: var(--text-normal);
    margin-bottom: 8px;
  }

  .subtitle {
    color: var(--text-subtle);
  }
}
