.notificationContainer {
  border-top: 1px solid var(--border-light);
  color: var(--text-normal-inverted);
  .enableNotification {
    display: inline;
  }
}
.enableNotificationClose {
  margin: 0 -20px;
  padding: 4px;
}
