@use "sass:color" as sasscolor;

// COLORS
// Maintain 1:1 with Figma palette @ https://www.figma.com/file/Ida3cWItzJUHPziwu37LE1/?node-id=5%3A8663
// If you add here, please add there as well (or tag someone to)

// Primary colors

$sequoia-green: #00a071;
$sequoia-black: #1b1917;
$dark-grey: #928c86;
$light-grey: #a8a39e;
$sequoia-white: #f5f3f2;

// Accent colors

$page-bg: #fbf7f0;

$bright-blue: #1f8ac4;
$bright-pink: #e8599e;
$bright-red: #eb2926;
$bright-orange: #f5571c;
$bright-yellow: #fab23a;

$dark-green: #007354;
$dark-blue: #006d96;
$dark-purple: #8b4c9e;
$dark-red: #ba080d;
$dark-orange: #c74200;
$dark-yellow: #b06300;

// UI elements

// Our primary black at different levels of translucence (against any background)
$sequoia-black-8-alpha: #1b1917cc;
$sequoia-black-7-alpha: #1b1917b3;
$sequoia-black-5-alpha: #1b191780;
$sequoia-black-3-alpha: #1b19174d;
$sequoia-black-1-alpha: #1b19171a;
$sequoia-white-8-alpha: #f5f3f2cc;
// Equivalent appearance to the translucent colors against page-bg fbf7f0, but they are solid
$sequoia-black-8-solid: #494745;
$sequoia-black-7-solid: #5f5e5d;
$sequoia-black-5-solid: #8d8c8b;
$sequoia-black-3-solid: #bbbab9;
$sequoia-black-1-solid: #e8e8e8;
$sequoia-white-8-solid: #f7f5f5;

// UI COLOR VARIABLES
// To-do: exclusively use (or add to) named palette above for consistency & easy evolution.
// Note that because we prefix "color." when referencing from templates, we don’t need to add "-color" to var names.

$white: #fff;
$conversation-cell-background: #fff;
$separator-line: #1b191720; // 0.3 of $sequoia-black-3-alpha would end in 17 but spread over 1px
$heavy-separator-line: $sequoia-black-7-alpha;
$sub-separator-line: $sequoia-black-1-alpha; // When $separator-line divides elements and you need another level of division
$thin-border: #1b191720; // looks a little too light, so this is a tiny big heavier.
$thin-border-opaque: #dddbd8; // This is a similar optical appearance to black-3, but without transparency.
$flashed-row: #e7ecee;
$attachment-file-background: rgba(27, 25, 23, 0.04);
$highlight-background: #0001;

// Text and links
// Note: we don’t use color to distinguish links. So we must underline where links are not obvious

$text: $sequoia-black;
$link-hover-text: $sequoia-black-7-solid;
$link-hover-background: sasscolor.adjust($sequoia-black, $alpha: -0.95);
$link-active-background: sasscolor.adjust($sequoia-black, $alpha: -0.9);
$disabled-text: $sequoia-black-5-solid;
$error-text: $bright-red;
$error-text-hover: sasscolor.adjust($error-text, $alpha: -0.15);
$error-text-active: sasscolor.adjust($error-text, $alpha: -0.45);

$text-inverted: $sequoia-white;
$link-hover-text-inverted: $sequoia-white-8-alpha;
$link-hover-background-inverted: sasscolor.adjust(
  $sequoia-white,
  $alpha: -0.95
);
$link-active-background-inverted: sasscolor.adjust(
  $sequoia-white,
  $alpha: -0.9
);

// Primary buttons are filled and show inverted text. Simple!
$primary-button-text: $text-inverted;
$primary-button-background: $sequoia-green;
// Hover
$primary-button-hover-background: #00694d;
// Active
$primary-button-active-background: #006048;
// Selected
$primary-button-disabled-background: #00a070b9;

// Secondary buttons are outlined. They inherit background and add a subtle bg on hover/active.
$secondary-button-text: $text;
$secondary-button-border: $sequoia-black-5-alpha;
// Hover
$secondary-button-hover-border: $sequoia-black-5-alpha;
$secondary-button-hover-background: $link-hover-background;
$secondary-button-hover-text: $sequoia-black;
// Active
$secondary-button-active-border: $sequoia-black-5-alpha;
$secondary-button-active-background: $link-active-background;
$secondary-button-active-text: $sequoia-black;
// Disabled
$secondary-button-disabled-border: $sequoia-black-3-solid;
$secondary-button-disabled-background: $sequoia-black-5-solid;
$secondary-button-disabled-text: $sequoia-black-3-solid;
// Selected
$secondary-button-selected-background: $sequoia-black-8-alpha;
$secondary-button-selected-border: $sequoia-white;
$secondary-button-selected-text: $sequoia-white;

// Tab buttons — as used in nav inherit secondary button colors
$nav-button-text: $text;
$nav-button-hover-background: $link-hover-background;
$nav-button-active-background-color: $link-active-background;
$nav-button-selected-background: $secondary-button-selected-background;
$nav-button-selected-text: $secondary-button-selected-text;

// Round icon button (investigate: do we need these separate declarations from secondary button colors?)
$round-icon-background: $page-bg;
$round-icon-disabled-border: $sequoia-black-3-alpha;
$round-icon-disabled-background: $sequoia-black-3-alpha;
$round-icon-border: $sequoia-black-5-alpha; // why is this different from color?
$round-icon: $sequoia-black;
$round-icon-active: $sequoia-black-8-alpha;
$round-icon-hover-background: $secondary-button-hover-background;
$round-icon-active-background: $secondary-button-active-background;

// Toolbar buttons (investigate: can these use secondary button hover & active?)
$toolbar-button-hover-background: #f7f7f7;
$toolbar-button-active-background: #f5f5f5;

// Pill inputs (radio select behavior) — use identical styling as secondary/default button
// Takes styling from default secondary button (could iterate pill input to use same classes+css)
$pill-input-text: $secondary-button-text;
$pill-input-border: $secondary-button-border;
// Hover
$pill-input-hover-border: $secondary-button-hover-border;
$pill-input-hover-background: $secondary-button-hover-background;
$pill-input-hover-text: $secondary-button-hover-text;
// Active
$pill-input-active-border: $secondary-button-active-border;
$pill-input-active-background: $secondary-button-active-background;
$pill-input-active-text: $secondary-button-active-text;
// Disabled
$pill-input-disabled-border: $secondary-button-disabled-border;
$pill-input-disabled-background: $secondary-button-disabled-background;
$pill-input-disabled-text: $secondary-button-disabled-text;
// Selected
$pill-input-selected-background: $secondary-button-selected-background;
$pill-input-selected-text: $secondary-button-selected-text;

// Squid
$squid-border: $thin-border-opaque;

// Compose
$message-suggestion-separator: $light-grey;

// Scout deals & memos
$approved-text: $sequoia-green;
$pending-due-diligence-text: $dark-yellow;
$visiblity-info-text: $sequoia-black-5-solid; // Maybe this can be an "info/metadata text" in the future
$visiblity-info-background: $attachment-file-background;

// Chat
$from-me-bg: $sequoia-green;
$from-me-text: $sequoia-white;

$from-them-bg: $white;
$from-them-text: $sequoia-black;

$time-text: $sequoia-black-5-alpha;
$username-text: $sequoia-black-5-alpha;
$reaction-count-text: $sequoia-black-5-alpha;
